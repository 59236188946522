import { createActions } from 'redux-actions';
import ValuationsTypes from '../action-types/valuations';

export const {
  getValuationsSettingsRequest,
  getValuationsSettingsSuccess,
  getValuationsSettingsError,

  getValuationsQuotesRequest,
  getValuationsQuotesSuccess,
  getValuationsQuotesError,

  getValuationsByPathRequest,
  getValuationsByPathSuccess,
  getValuationsByPathError,

  waitingValuationContent,
} = createActions({
  [ValuationsTypes.GET_VALUATIONS_SETTINGS.REQUEST]: payload => payload,
  [ValuationsTypes.GET_VALUATIONS_SETTINGS.SUCCESS]: payload => payload,
  [ValuationsTypes.GET_VALUATIONS_SETTINGS.ERROR]: payload => payload,

  [ValuationsTypes.GET_VALUATIONS_QUOTES.REQUEST]: payload => payload,
  [ValuationsTypes.GET_VALUATIONS_QUOTES.SUCCESS]: payload => payload,
  [ValuationsTypes.GET_VALUATIONS_QUOTES.ERROR]: payload => payload,

  [ValuationsTypes.GET_VALUATIONS_BY_PATH.REQUEST]: payload => payload,
  [ValuationsTypes.GET_VALUATIONS_BY_PATH.SUCCESS]: payload => payload,
  [ValuationsTypes.GET_VALUATIONS_BY_PATH.ERROR]: payload => payload,

  [ValuationsTypes.WAITING_VALUATION_CONTENT]: payload => payload,
});
