import keyMirror from 'keymirror';
import { createAsyncActionSet } from '../lib/action-helper';

const syncActionSet = keyMirror({
  START_ENTER_DETAILS_TIMER: undefined,
  STOP_ENTER_DETAILS_TIMER: undefined,
});

export default {
  ...syncActionSet,
  CREATE_VALUATION_QUOTE: createAsyncActionSet('CREATE_VALUATION_QUOTE'),
  SET_VALUATION_VALUES: createAsyncActionSet('SET_VALUATION_VALUES'),
  SET_VALUATION_OPTION: createAsyncActionSet('SET_VALUATION_OPTION'),
  CLEAR_VALUATION_QUOTE_PROCESS_DATA: createAsyncActionSet('CLEAR_VALUATION_QUOTE_PROCESS_DATA'),
};
