import { createActions } from 'redux-actions';
import MediaTypes from '../action-types/media';

export const {
  getMediaRequest,
  getMediaSuccess,
  getMediaError,

  getRelatedRequest,
  getRelatedSuccess,
  getRelatedError,

  getMediaCentreRequest,
  getMediaCentreSuccess,
  getMediaCentreError,

  getMediaCategoriesRequest,
  getMediaCategoriesSuccess,
  getMediaCategoriesError,

  getIndividualByPathRequest,
  getIndividualByPathSuccess,
  getIndividualByPathError,

  categoriesContentByType,
} = createActions({
  [MediaTypes.GET_MEDIA.REQUEST]: payload => payload,
  [MediaTypes.GET_MEDIA.SUCCESS]: payload => payload,
  [MediaTypes.GET_MEDIA.ERROR]: payload => payload,

  [MediaTypes.GET_RELATED.REQUEST]: payload => payload,
  [MediaTypes.GET_RELATED.SUCCESS]: payload => payload,
  [MediaTypes.GET_RELATED.ERROR]: payload => payload,

  [MediaTypes.GET_MEDIA_CENTRE.REQUEST]: payload => payload,
  [MediaTypes.GET_MEDIA_CENTRE.SUCCESS]: payload => payload,
  [MediaTypes.GET_MEDIA_CENTRE.ERROR]: payload => payload,

  [MediaTypes.GET_MEDIA_CATEGORIES.REQUEST]: payload => payload,
  [MediaTypes.GET_MEDIA_CATEGORIES.SUCCESS]: payload => payload,
  [MediaTypes.GET_MEDIA_CATEGORIES.ERROR]: payload => payload,

  [MediaTypes.GET_INDIVIDUAL_BY_PATH.REQUEST]: payload => payload,
  [MediaTypes.GET_INDIVIDUAL_BY_PATH.SUCCESS]: payload => payload,
  [MediaTypes.GET_INDIVIDUAL_BY_PATH.ERROR]: payload => payload,

  [MediaTypes.CATEGORIES_CONTENT_BY_TYPE]: payload => payload,
});
