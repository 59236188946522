import cookie from 'js-cookie';
import Cookies from 'cookies';

export const setCookie = (key, value, req = null, res = null, expires = 1) => {
  if (process.browser) {
    cookie.set(key, value, {
      expires,
      path: '/',
    });
  } else if (req && res) {
    const cookies = new Cookies(req, res);
    cookies.set(key, value, {
      httpOnly: false,
    });
  }
};

export const removeCookie = (key, req = null, res = null) => {
  if (process.browser) {
    cookie.remove(key, {
      expires: 1,
    });
  } else if (req && res) {
    const cookies = new Cookies(req, res);
    cookies.set(key, '', {
      expires: 'Thu, 01 Jan 1970 00:00:01 GMT;',
      path: '/',
      maxAge: -1,
      overwrite: true,
    });
  }
};

export const getCookie = (key, req) => (process.browser ? getCookieFromBrowser(key) : getCookieFromServer(key, req));

const getCookieFromBrowser = key => cookie.get(key);

const getCookieFromServer = (key, req) => {
  if (!req || !req.headers || !req.headers.cookie) {
    return undefined;
  }
  const rawCookie = req.headers.cookie.split(';').find(c => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return undefined;
  }
  return rawCookie.split('=')[1];
};

export const preserveToastOnSSR = (message, kind, req = null, res = null) => {
  const toast = encodeURI(
    JSON.stringify({
      message,
      kind,
    }),
  );

  setCookie('show_toast', toast, req, res);
};
