import { createActions } from 'redux-actions';
import WarrantiesTypes from '../action-types/warranties';

export const {
  getWarrantiesSettingsRequest,
  getWarrantiesSettingsSuccess,
  getWarrantiesSettingsError,

  getFleetWarrantiesSettingsRequest,
  getFleetWarrantiesSettingsSuccess,
  getFleetWarrantiesSettingsError,

  getWarrantiesByTypeRequest,
  getWarrantiesByTypeSuccess,
  getWarrantiesByTypeError,

  getWarrantiesByPathRequest,
  getWarrantiesByPathSuccess,
  getWarrantiesByPathError,

  getWarrantiesQuoteRequest,
  getWarrantiesQuoteSuccess,
  getWarrantiesQuoteError,

  sendContactUsEnquiryRequest,
  sendContactUsEnquirySuccess,
  sendContactUsEnquiryError,

  getWarrantiesShortLinkRequest,
  getWarrantiesShortLinkSuccess,
  getWarrantiesShortLinkError

} = createActions({
  [WarrantiesTypes.GET_WARRANTIES_SETTINGS.REQUEST]: payload => payload,
  [WarrantiesTypes.GET_WARRANTIES_SETTINGS.SUCCESS]: payload => payload,
  [WarrantiesTypes.GET_WARRANTIES_SETTINGS.ERROR]: payload => payload,

  [WarrantiesTypes.GET_FLEET_WARRANTIES_SETTINGS.REQUEST]: payload => payload,
  [WarrantiesTypes.GET_FLEET_WARRANTIES_SETTINGS.SUCCESS]: payload => payload,
  [WarrantiesTypes.GET_FLEET_WARRANTIES_SETTINGS.ERROR]: payload => payload,

  [WarrantiesTypes.GET_WARRANTIES_BY_TYPE.REQUEST]: payload => payload,
  [WarrantiesTypes.GET_WARRANTIES_BY_TYPE.SUCCESS]: payload => payload,
  [WarrantiesTypes.GET_WARRANTIES_BY_TYPE.ERROR]: payload => payload,

  [WarrantiesTypes.GET_WARRANTIES_BY_PATH.REQUEST]: payload => payload,
  [WarrantiesTypes.GET_WARRANTIES_BY_PATH.SUCCESS]: payload => payload,
  [WarrantiesTypes.GET_WARRANTIES_BY_PATH.ERROR]: payload => payload,

  [WarrantiesTypes.GET_WARRANTIES_QUOTE.REQUEST]: payload => payload,
  [WarrantiesTypes.GET_WARRANTIES_QUOTE.SUCCESS]: payload => payload,
  [WarrantiesTypes.GET_WARRANTIES_QUOTE.ERROR]: payload => payload,

  [WarrantiesTypes.SEND_CONTACT_US_ENQUIRY.REQUEST]: (data, actions) => ({ data, actions }),
  [WarrantiesTypes.SEND_CONTACT_US_ENQUIRY.SUCCESS]: data => data,
  [WarrantiesTypes.SEND_CONTACT_US_ENQUIRY.ERROR]: data => data,

  [WarrantiesTypes.GET_WARRANTIES_MANUFACTURERS.REQUEST]: payload => payload,
  [WarrantiesTypes.GET_WARRANTIES_MANUFACTURERS.SUCCESS]: payload => payload,
  [WarrantiesTypes.GET_WARRANTIES_MANUFACTURERS.ERROR]: payload => payload,

  [WarrantiesTypes.GET_WARRANTIES_SHORT_LINK.REQUEST]: payload => payload,
  [WarrantiesTypes.GET_WARRANTIES_SHORT_LINK.SUCCESS]: payload => payload,
  [WarrantiesTypes.GET_WARRANTIES_SHORT_LINK.ERROR]: payload => payload,
});
