import { createActions } from 'redux-actions';
import { md5 } from 'lib/encrypt';
import { segmentIdentify } from 'lib/segment';
import moment from 'moment';
import UserTypes from '../action-types/user';

export const {
  authenticate,
  userLoginRequest,
  userLoginSuccess,
  userLoginError,
  refreshTokenRequest,
  refreshTokenSuccess,
  refreshTokenError,
  getUserDataRequest,
  getUserDataSuccess,
  getUserDataError,
  userLogoutRequest,
  userLogoutSuccess,
  userLogoutError,
  userSignupRequest,
  userSignupSuccess,
  userSignupError,
  setEmail,
  setGdprConsent,
  setFirstName,
  setLastName,
  setPhoneNumber,
  setConfirmationValue,
  watchUserChannelProgress,
  watchUserChannelFinish,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordError,
  forgotPasswordRequest,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordError,
  editProfileRequest,
  editProfileSuccess,
  editProfileError,
  deleteAccountRequest,
  deleteAccountSuccess,
  deleteAccountError,
  personalDataRequest,
  personalDataSuccess,
  personalDataError,
  createSupportTicketRequest,
  createSupportTicketSuccess,
  createSupportTicketError,
  dropUserData,

  loginWith3rdPartyRequest,
  loginWith3rdPartySuccess,
  loginWith3rdPartyError,

  getPaymentMethodsRequest,
  getPaymentMethodsSuccess,
  getPaymentMethodsError,

  setLoginBusy,
} = createActions({
  [UserTypes.AUTHENTICATE]: data => data,
  [UserTypes.USER_LOGIN.REQUEST]: (data, actions, redirect, shouldShowMessage) => ({
    data,
    actions,
    redirect,
    shouldShowMessage,
  }),
  [UserTypes.USER_LOGIN.SUCCESS]: data => data,
  [UserTypes.USER_LOGIN.ERROR]: error => error,
  [UserTypes.REFRESH_TOKEN.REQUEST]: payload => payload,
  [UserTypes.REFRESH_TOKEN.SUCCESS]: data => data,
  [UserTypes.REFRESH_TOKEN.ERROR]: error => error,
  [UserTypes.GET_USER_DATA.REQUEST]: payload => payload,
  [UserTypes.GET_USER_DATA.SUCCESS]: data => {
    segmentIdentify(md5(data.email), {
      name: `${data.firstName} ${data.lastName}`,
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      plan: data.type,
      createdAt: moment().format('YYYY-MM-DD hh:mm:ss'),
    });
    return data;
  },
  [UserTypes.GET_USER_DATA.ERROR]: error => error,
  [UserTypes.USER_LOGOUT.REQUEST]: (data, actions, redirect) => ({ data, actions, redirect }),
  [UserTypes.USER_LOGOUT.SUCCESS]: data => data,
  [UserTypes.USER_LOGOUT.ERROR]: error => error,
  [UserTypes.USER_SIGNUP.REQUEST]: (data, actions, redirect, callback) => ({
    data,
    actions,
    redirect,
    callback,
  }),
  [UserTypes.USER_SIGNUP.SUCCESS]: data => data,
  [UserTypes.USER_SIGNUP.ERROR]: error => error,
  [UserTypes.SET_EMAIL]: email => ({ email }),
  [UserTypes.SET_GDPR_CONSENT]: terms => ({ terms }),
  [UserTypes.SET_FIRST_NAME]: firstName => ({ firstName }),
  [UserTypes.SET_LAST_NAME]: lastName => ({ lastName }),
  [UserTypes.SET_PHONE_NUMBER]: phoneNumber => ({ phoneNumber }),
  [UserTypes.SET_CONFIRMATION_VALUE]: confirmationValue => ({ confirmationValue }),
  [UserTypes.WATCH_USER_CHANNEL.PROGRESS]: () => {},
  [UserTypes.WATCH_USER_CHANNEL.FINISH]: () => {},
  [UserTypes.CHANGE_PASSWORD.REQUEST]: (data, actions, redirect) => ({ data, actions, redirect }),
  [UserTypes.CHANGE_PASSWORD.SUCCESS]: data => data,
  [UserTypes.CHANGE_PASSWORD.ERROR]: error => error,
  [UserTypes.FORGOT_PASSWORD.REQUEST]: (data, actions, redirect) => ({ data, actions, redirect }),
  [UserTypes.FORGOT_PASSWORD.SUCCESS]: () => {},
  [UserTypes.FORGOT_PASSWORD.ERROR]: error => error,
  [UserTypes.RESET_PASSWORD.REQUEST]: (data, actions, redirect) => ({ data, actions, redirect }),
  [UserTypes.RESET_PASSWORD.SUCCESS]: data => data,
  [UserTypes.RESET_PASSWORD.ERROR]: error => error,
  [UserTypes.EDIT_PROFILE.REQUEST]: (data, actions) => ({ data, actions }),
  [UserTypes.EDIT_PROFILE.SUCCESS]: () => {},
  [UserTypes.EDIT_PROFILE.ERROR]: error => error,
  [UserTypes.DELETE_ACCOUNT.REQUEST]: data => data,
  [UserTypes.DELETE_ACCOUNT.SUCCESS]: data => data,
  [UserTypes.DELETE_ACCOUNT.ERROR]: error => error,
  [UserTypes.PERSONAL_DATA.REQUEST]: data => data,
  [UserTypes.PERSONAL_DATA.SUCCESS]: () => {},
  [UserTypes.PERSONAL_DATA.ERROR]: error => error,
  [UserTypes.CREATE_SUPPORT_TICKET.REQUEST]: data => data,
  [UserTypes.CREATE_SUPPORT_TICKET.SUCCESS]: () => {},
  [UserTypes.CREATE_SUPPORT_TICKET.ERROR]: error => error,
  [UserTypes.DROP_USER_DATA]: () => {},

  [UserTypes.LOGIN_WITH_3RD_PARTY.REQUEST]: data => data,
  [UserTypes.LOGIN_WITH_3RD_PARTY.SUCCESS]: data => data,
  [UserTypes.LOGIN_WITH_3RD_PARTY.ERROR]: error => error,

  [UserTypes.GET_PAYMENT_METHODS.REQUEST]: data => data,
  [UserTypes.GET_PAYMENT_METHODS.SUCCESS]: data => data,
  [UserTypes.GET_PAYMENT_METHODS.ERROR]: error => error,

  [UserTypes.SET_LOGIN_BUSY]: data => data,
});
