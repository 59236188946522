import { createActions } from 'redux-actions';
import FinanceTypes from '../action-types/finance';

export const {
  getFinanceSettingsRequest,
  getFinanceSettingsSuccess,
  getFinanceSettingsError,

  getFinanceByTypeRequest,
  getFinanceByTypeSuccess,
  getFinanceByTypeError,

  getFinanceManufacturersRequest,
  getFinanceManufacturersSuccess,
  getFinanceManufacturersError,

  getFinanceByPathRequest,
  getFinanceByPathSuccess,
  getFinanceByPathError,

  getContinueWithFinanceQuoteShortLinkRequest,
  getContinueWithFinanceQuoteShortLinkSuccess,
  getContinueWithFinanceQuoteShortLinkError,

  setCalculatorData,
} = createActions({
  [FinanceTypes.GET_FINANCE_SETTINGS.REQUEST]: payload => payload,
  [FinanceTypes.GET_FINANCE_SETTINGS.SUCCESS]: payload => payload,
  [FinanceTypes.GET_FINANCE_SETTINGS.ERROR]: payload => payload,

  [FinanceTypes.GET_FINANCE_BY_TYPE.REQUEST]: payload => payload,
  [FinanceTypes.GET_FINANCE_BY_TYPE.SUCCESS]: payload => payload,
  [FinanceTypes.GET_FINANCE_BY_TYPE.ERROR]: payload => payload,

  [FinanceTypes.GET_FINANCE_MANUFACTURERS.REQUEST]: payload => payload,
  [FinanceTypes.GET_FINANCE_MANUFACTURERS.SUCCESS]: payload => payload,
  [FinanceTypes.GET_FINANCE_MANUFACTURERS.ERROR]: payload => payload,

  [FinanceTypes.GET_FINANCE_BY_PATH.REQUEST]: payload => payload,
  [FinanceTypes.GET_FINANCE_BY_PATH.SUCCESS]: payload => payload,
  [FinanceTypes.GET_FINANCE_BY_PATH.ERROR]: payload => payload,

  [FinanceTypes.GET_CONTINUE_WITH_FINANCE_QUOTE_SHORT_LINK.REQUEST]: payload => payload,
  [FinanceTypes.GET_CONTINUE_WITH_FINANCE_QUOTE_SHORT_LINK.SUCCESS]: payload => payload,
  [FinanceTypes.GET_CONTINUE_WITH_FINANCE_QUOTE_SHORT_LINK.ERROR]: payload => payload,

  [FinanceTypes.SET_CALCULATOR_DATA]: payload => payload,
});
