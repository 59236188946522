import { createAsyncActionSet } from '../lib/action-helper';

export default {
  GET_MEDIA: createAsyncActionSet('GET_MEDIA'),
  GET_RELATED: createAsyncActionSet('GET_RELATED'),
  GET_MEDIA_CENTRE: createAsyncActionSet('GET_MEDIA_CENTRE'),
  GET_MEDIA_CATEGORIES: createAsyncActionSet('GET_MEDIA_CATEGORIES'),
  GET_INDIVIDUAL_BY_PATH: createAsyncActionSet('GET_INDIVIDUAL_BY_PATH'),
  CATEGORIES_CONTENT_BY_TYPE: 'CATEGORIES_CONTENT_BY_TYPE',
};
