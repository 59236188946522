import { createActions } from 'redux-actions';
import VehicleInspectionsTypes from '../action-types/vehicleInspections';

export const {
  getVehicleInspectionsSettingsRequest,
  getVehicleInspectionsSettingsSuccess,
  getVehicleInspectionsSettingsError,

  getVehicleInspectionsByTypeRequest,
  getVehicleInspectionsByTypeSuccess,
  getVehicleInspectionsByTypeError,

  getVehicleInspectionsByPathRequest,
  getVehicleInspectionsByPathSuccess,
  getVehicleInspectionsByPathError,
} = createActions({
  [VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_SETTINGS.REQUEST]: payload => payload,
  [VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_SETTINGS.SUCCESS]: payload => payload,
  [VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_SETTINGS.ERROR]: payload => payload,

  [VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_BY_TYPE.REQUEST]: payload => payload,
  [VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_BY_TYPE.SUCCESS]: payload => payload,
  [VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_BY_TYPE.ERROR]: payload => payload,

  [VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_BY_PATH.REQUEST]: payload => payload,
  [VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_BY_PATH.SUCCESS]: payload => payload,
  [VehicleInspectionsTypes.GET_VEHICLE_INSPECTIONS_BY_PATH.ERROR]: payload => payload,
});
