import { createActions } from 'redux-actions';
import { segmentTrack } from 'lib/segment';

import ValuationQuoteTypes from '../action-types/valuationQuoteProcess';

export const {
  setValuationValuesRequest,
  createValuationQuoteSuccess,
  createValuationQuoteError,
  setValuationOptionRequest,
  clearValuationQuoteProcessData,
} = createActions({
  [ValuationQuoteTypes.SET_VALUATION_VALUES.REQUEST]: payload => payload,
  [ValuationQuoteTypes.CREATE_VALUATION_QUOTE.REQUEST]: payload => payload,
  [ValuationQuoteTypes.CREATE_VALUATION_QUOTE.SUCCESS]: payload => payload,
  [ValuationQuoteTypes.CREATE_VALUATION_QUOTE.ERROR]: payload => payload,
  [ValuationQuoteTypes.SET_VALUATION_OPTION.REQUEST]: payload => payload,
  [ValuationQuoteTypes.CLEAR_VALUATION_QUOTE_PROCESS_DATA.actionName]: () => {},
});
