import * as md5 from 'md5';

const processMd5Message = (message, options, processOptions = { lower: true }) => {
  let newMessage = message;
  if (processOptions.lower && typeof message === 'string') {
    newMessage = message?.toLowerCase();
  }
  return md5(newMessage, options);
};

export { processMd5Message as md5 };
