import { createAsyncActionSet } from '../lib/action-helper';

export default {
  GET_FINANCE_SETTINGS: createAsyncActionSet('GET_FINANCE_SETTINGS'),
  GET_FINANCE_BY_TYPE: createAsyncActionSet('GET_FINANCE_BY_TYPE'),
  GET_FINANCE_BY_PATH: createAsyncActionSet('GET_FINANCE_BY_PATH'),
  GET_FINANCE_MANUFACTURERS: createAsyncActionSet('GET_FINANCE_MANUFACTURERS'),
  GET_CONTINUE_WITH_FINANCE_QUOTE_SHORT_LINK: createAsyncActionSet('GET_CONTINUE_WITH_FINANCE_QUOTE_SHORT_LINK'),
  SET_CALCULATOR_DATA: 'SET_CALCULATOR_DATA',
};
