import { createAsyncActionSet, createDelayedActionsSet } from '../lib/action-helper';

export default {
  // GETTERS
  GET_AUCTIONS: createAsyncActionSet('GET_AUCTIONS'),
  GET_CAR_MAKES_FILTERS: createAsyncActionSet('GET_CAR_MAKES_FILTERS'),
  GET_CAR_MODELS_FILTERS: createAsyncActionSet('GET_CAR_MODELS_FILTERS'),
  GET_MODEL_YEAR_FILTERS: createAsyncActionSet('GET_MODEL_YEAR_FILTERS'),
  GET_COLOR_FILTERS: createAsyncActionSet('GET_COLOR_FILTERS'),
  GET_MILEAGE_FILTERS: createAsyncActionSet('GET_MILEAGE_FILTERS'),
  GET_TRANSMISSION_FILTERS: createAsyncActionSet('GET_TRANSMISSION_FILTERS'),
  GET_FUEL_FILTERS: createAsyncActionSet('GET_FUEL_FILTERS'),
  GET_SECOND_STAGE_DATA: createAsyncActionSet('GET_SECOND_STAGE_DATA'),
  GET_POSTCODE: createAsyncActionSet('GET_POSTCODE'),
  GET_THIRD_STAGE_DATA: createAsyncActionSet('GET_THIRD_STAGE_DATA'),
  GET_POSTCODE_BY_LOCATION: createAsyncActionSet('GET_POSTCODE_BY_LOCATION'),

  // SETTERS
  CHANGE_CAR_MAKES_FILTERS: createAsyncActionSet('CHANGE_CAR_MAKES_FILTERS'),
  CHANGE_CAR_MODELS_FILTERS: createAsyncActionSet('CHANGE_CAR_MODELS_FILTERS'),
  CHANGE_MODEL_YEAR_FILTERS: createAsyncActionSet('CHANGE_MODEL_YEAR_FILTERS'),
  CHANGE_COLOR_FILTERS: createAsyncActionSet('CHANGE_COLOR_FILTERS'),

  CHANGE_MILEAGE_FILTERS: createDelayedActionsSet('CHANGE_MILEAGE_FILTERS'),
  CHANGE_POSTCODE_FILTER: 'CHANGE_POSTCODE_FILTER',

  CHANGE_TRANSMISSION_FILTERS: createAsyncActionSet('CHANGE_TRANSMISSION_FILTERS'),
  CHANGE_FUEL_FILTERS: createAsyncActionSet('CHANGE_FUEL_FILTERS'),
  CHANGE_PHRASE_FILTER: createAsyncActionSet('CHANGE_PHRASE_FILTER'),
  DROP_FILTERS: createAsyncActionSet('DROP_FILTERS'),
  CHANGE_ALL_FILTERS: createAsyncActionSet('CHANGE_ALL_FILTERS'),

  SELECT_ALL_LISTINGS: createAsyncActionSet('SELECT_ALL_LISTINGS'),
  SELECT_AUCTION_LISTINGS: createAsyncActionSet('SELECT_AUCTION_LISTINGS'),
  SELECT_MARKED_FOR_SCRAP_LISTINGS: createAsyncActionSet('SELECT_MARKED_FOR_SCRAP_LISTINGS'),
  VIEW_GRID_FORMAT_CHANGE: createAsyncActionSet('VIEW_GRID_FORMAT_CHANGE'),
  SORT_BY_CHANGE: createAsyncActionSet('SORT_BY_CHANGE'),
  PAGE_CHANGE: createAsyncActionSet('PAGE_CHANGE'),
  AUCTIONS_DISPLAY_PER_PAGE_CHANGE: createAsyncActionSet('AUCTIONS_DISPLAY_PER_PAGE_CHANGE'),
  SEARCH_AUCTIONS: createAsyncActionSet('SEARCH_AUCTIONS'),

  UPDATE_AUCTIONS_BID: 'UPDATE_AUCTIONS_BID',

  // GET_AUCTIONS_BY_VALUE: createAsyncActionSet('GET_AUCTIONS_BY_VALUE'),
  // CHANGE_AUCTIONS_PAGE: createAsyncActionSet('CHANGE_AUCTIONS_PAGE'),
  // CHANGE_AUCTIONS_LIMIT: createAsyncActionSet('CHANGE_AUCTIONS_LIMIT'),
  // FILTER_AUCTIONS: createAsyncActionSet('FILTER_AUCTIONS'),
  // SORT_AUCTIONS: createAsyncActionSet('SORT_AUCTIONS'),
  // CHANGE_AUCTIONS_LISTING_TYPE: createAsyncActionSet('CHANGE_AUCTIONS_LISTING_TYPE'),
};
