import { createAsyncActionSet } from '../lib/action-helper';

export default {
  GET_WARRANTIES_SETTINGS: createAsyncActionSet('GET_WARRANTIES_SETTINGS'),
  GET_FLEET_WARRANTIES_SETTINGS: createAsyncActionSet('GET_FLEET_WARRANTIES_SETTINGS'),
  GET_WARRANTIES_BY_TYPE: createAsyncActionSet('GET_WARRANTIES_BY_TYPE'),
  GET_WARRANTIES_BY_PATH: createAsyncActionSet('GET_WARRANTIES_BY_PATH'),
  GET_WARRANTIES_QUOTE: createAsyncActionSet('GET_WARRANTIES_QUOTE'),
  SEND_CONTACT_US_ENQUIRY: createAsyncActionSet('SEND_CONTACT_US_ENQUIRY'),
  GET_WARRANTIES_MANUFACTURERS: createAsyncActionSet('GET_WARRANTIES_MANUFACTURERS'),
  GET_WARRANTIES_SHORT_LINK: createAsyncActionSet('GET_WARRANTIES_SHORT_LINK')
};
